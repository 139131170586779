.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-bottom: 1px #F1F1F1 solid;
}

.buttonContainer {
    padding-right: 15px;
}

.button {
    width: 100px;
    margin: 5px !important;
}

.textButton {
    color: white;
    font-weight: 600;
    font-size: 12px;
}

.textOutlinedButton {
    color: red;
    font-weight: 600;
    font-size: 12px;
}