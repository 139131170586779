.container {
    width: 100%;
    height: 250px;
    background-color: #C0C0C0;
}

.button {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50px;
    margin-top: 10px;
    margin-left: 87%;
    margin-top: -240px;
    cursor: pointer;
    background-color: #C0C0C0;
}

.icon {
    width: 25px;
    height: 25px;
}

.containerImage {
    width: 100%;
    height: 220px;
    background-color: #C0C0C0;
}

.loading {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: 5px;
}