.container {
    display: flex;
    align-items: center;
    border: 1px #234a83 solid;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5%;
    padding-right: 10%;
    cursor: pointer;
    margin-left: 10%;
    margin-right: 10%;
}
.image {
    width: 35px;
    height: 40px;
}

.text { 
    width: 80%;
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
    margin-top: 10px;
}