input[type='submit'] {
    height: 35px;
    border: none !important;
    border-radius: 5px;
}

.button-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    height: 35px;
    border-radius: 5px;
    font-size: 13px;
}
