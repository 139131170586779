.screen {
    height: 100vh;
    background-color: white;
}

.content {
    height: 100vh;
}

.containerOption {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.containerOption:hover {
    background-color: #F1F1F1;
}

.textContainerOption {
   margin-left: 20px;
    margin-right: 20px;
}

.textOption {
    color: #414141;
    font-weight: 500;
    margin: 0px;
}

.subtextOption {
    font-size: 14px;
    color: #808080;
    font-weight: 500;
    margin: 0px;

}

.iconOption {
    color: #414141;
    width: 33px;
    height: 33px;
}

.dividerOption {
    width: '100%';
    height: 1px;
    background-color: #F1F1F1;
}