.container {}

.button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-top: -20px;
    margin-left: 53px;
    position: relative;
    background-color: #C0C0C0;
    cursor: pointer;
}

.icon {
    width: 23px;
    height: 23px;
}

.containerImage {
    width: 100%;
    height: 220px;
    background-color: #C0C0C0;
}

.loading {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: 5px;
}