.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px #F1F1F1 solid;
    padding-right: 20px;
}

.container:hover {
    background-color: #F1F1F1;
}