
.container {
    width: 55px;
    padding-top: 5px;
    margin-left: 15px;
    cursor: pointer;
}

.profilePicture {
    width: 55px;
    height: 55px;
    margin-left: 4px;
    border-radius: 5px;
    border: 1px #C0C0C0 solid;
}

.profilePicture:hover {
    border: 2px #25447e solid;
}

.name {
    color: #25447e;
    width: 55px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
}

.containerTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    width: 40px;
    height: 40px;
}
